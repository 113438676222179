.client-box{
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    border: 1px solid rgb(204, 198, 198);
    padding: 30px;
    width: 700px;
    /* text-align: center; */
}
.input-field{
    padding: 10px;
    width: 500px;
    /* margin-bottom: 30px; */
    border: 1px solid rgb(7, 7, 6);
    
}
.btn-field{
    width: 100px;
}