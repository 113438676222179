.run-assessment-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 2rem;
    gap: 1.2rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

    .run-assesse-label {
        font-weight: bold;
    }

    .run-type-controls {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
}

// #clientId, 
// #assessment,
// .btn-primary:hover,
// .btn-primary:active,
// .btn-primary:focus{
//     background: none !important;
//     color: #000;
// }

.date-img {
    width: 10px;
}

.run-loader-img {
    width: 50%;
    height: 50%;
}

// .datePicker.form-control-active {
//     border: 1px solid #2b2b2b !important;
// }
// .datePicker {
//     border-radius: 4px;
//     border: 1px solid #ced4da !important;
//     width: 725px;
// }
// .datePicker:focus {
//     border: 3.85px solid #38cad6 !important;
//     outline: none !important;
// }