@import '../../../shared/scss/partials/variables';
.kpi {
    border-bottom: 1px solid $border-color;
    padding: 1rem;

    .kpi-config-delete {
        display: flex;
        align-items: center;

    }

    .kpi-config-delete img {
        width: 1.25rem;
        height: 1.25rem;
    }

    .kpi-config-delete img.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.kpi:last-child {
    border: none;
}

// .btn-white:hover,
// .btn-white:active {
//     background-color: #FFFFFF;
//     color: #000000;
// }

// .btn-disabled {
//     opacity: 0.5;
//     background-color: #51087E !important;
//     pointer-events: none;
// }

.text-editor {
    .wrapper {
        // background-color: white;
        .quill {
            .ql-toolbar {
                .ql-snow .ql-picker {
                    color: $primary;
                }
                // background-color: $grey-light;
                // border-top-left-radius: 4px !important;
                // border-top-right-radius: 4px !important;
            }
            .ql-editor {
            }
        }
    }
}
.defaultUI {
    background-color: $info;
    padding: 0vh 2vw;
    border: 1px solid $info ;
    border-radius: 25px;
    margin-left: 20px;
    color: #FFF;
    font-size: x-small;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;

}

.modal-tile {
    font-weight: bolder;
    padding: 1vh 0vw;
    width: 150px;
}

.btn-grey-light:hover,
.btn-grey-light:active {
    background-color: $secondary !important;
    color: #000 !important;
}
.modal-body {
    #assessmentsettings {
            .nav-tabs {
                border: #FFFF !important;
                background-color: $primary !important;
                color: #FFF !important;
    
                .nav-link.active {
                    border: 1px solid $primary !important;
                    border-radius: 4px !important;
                    color: $primary !important;
                }
    
                .nav-link:hover {
                    border: #FFFF !important;
                    border-radius: 0% !important;
                }
                .nav-link {
                    border: 1px solid $primary !important;
                    border-radius: 4px !important;
                    color: #FFF !important;
                    width: 100% !important;
                    padding: 1vh 5vw;
                }
    
                .nav-item {
                    // width: 10% !important;
                }
            }
    }
    .text-area {
        width: 100%;
        border: 1px solid $grey-dark;
        height: 50px;
        padding-left: 15px;
        vertical-align: middle;
    }
    .instruction-textarea {
        border: 1px solid $grey-dark;
        textarea {
            border: none !important;
            padding-left: 15px;
            vertical-align: middle;
            font-size: small;
            // height: 50px;
        }
    }
    .assimg {
        width: 70px;
        height: 50px;
        border: 1px solid $grey-dark;
    }
    label {
        font-size: small;
    }
    .upload-component {
        border: 1px solid $grey-dark;
        width: 100%;
        height: 50px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
    .ortext {
        padding: 1vh 2vw;
        
    }
}
.modal-head {
    font-weight: bolder;
}

.form-check-input {
    // width: 1.2em !important; 
    // height: 1.2em !important;
    // margin: 0vh 0.5vw;
    border: 1px solid black !important;
}
.assessment-add-container {
   

    .btn-white:hover,
    .btn-white:active {
        border: none !important;
    }

}

.w-90 {
    width: 90%;
}

.w-80 {
    width: 80%;
}

.w-60 {
    width: 60%;
}

.w-55 {
    width: 55%;
}

.w-50 {
    width: 50%;
}

.w-40 {
    width: 40%;
}

.w-30 {
    width: 30%;
}

.w-20 {
    width: 20%;
}

.w-18 {
    width: 18%;
}

.w-15 {
    width: 15%;
}

.w-10 {
    width: 10%;
}

.w-5 {
    width: 5%;
}

.mt-20 {
    margin-top: 20px;
}

.assessment-add-container {
    .menumodal {
        background-color: #FFF;
        position: absolute;
        top: 70px;
        font-size: smaller;
        width: 20%;
        button {
            font-size: smaller;
        }
    }
    width: 100%;
    height: 100%;

    .assessment-header-container {
        gap: 0.5rem;
        padding: 10px 10px 10px 10px;

        img {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    .assessment-container {
        display: flex;
        height: 100%;
    }

    .question-side-bar {
        width: 15%;
        background-color: #f5f5f5;

        .question-design {
            display: flex;
            align-items: center;
            place-content: space-between;
            padding: 10px;
            border-radius: 5px;
            font-size: 15px;

            .question-type {
                display: flex;
                align-items: center;
                font-weight: bold;
            }
        }

        .question-design:hover {
            background-color: $primary;
            color: #ffffff;
        }
    }

    .cursor {
        cursor: pointer;
    }

    .section-container {
        width: 100%;
        display: flex;
        flex-direction: column;

        .top-section {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .top-section-label {
                width: 20%;
            }

            .top-section-input {
                width: 60%;
            }
        }

        .middle-section {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .button-container {
                display: flex;
                gap: 1rem;
                justify-content: flex-end;
            }

            .section {
                display: flex;
                flex-direction: column;
                padding: 32px;
                border-radius: 5px;
                gap: 10px;
                // box-shadow: rgba(94, 94, 94, 0.4) 0px 3px 8px;
                // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

                .section-label {
                    width: 10%;
                }
            }

            .section-title-container {
                cursor: pointer;
            }

            .section-title {
                display: flex;
                align-items: center;
                font-weight: bold;
            }
            .borderui {
                border-bottom: 1px solid $grey-dark;
                padding-bottom: 2vh;
            }

            .question-container {
                display: flex;
                padding: 15px 0px 15px 0px;
                width: 100%;
                // box-shadow: rgba(94, 94, 94, 0.4) 0px 3px 8px;

                .question-mandatory {
                    width: 5%;
                    display: flex;
                    align-items: center;
                    place-content: center;

                    input {
                        cursor: pointer;
                    }
                }

                .question-title {
                    // width: 85%; 
                    // display: flex;
                    // flex-direction: column;
                    // gap: 1rem;
                    // border-left: 1px solid #51087E; 
                    // padding: 0px 20px 0px 20px;
                }

                .question-actions {
                    width: 10%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;

                    img {
                        width: 20%;
                        cursor: pointer;
                        padding: 5px;
                    }
                }
            }
        }

        .bottom-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 15px 15px;
            gap: 10px;
        }
    }
}
.dropdownview {
    width: 50%;
    button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #dee2e6;
    }
    button:hover, .button:active {
        border: 1px solid #dee2e6;
    }
}
.link {
    color: $link;
    font-size: 10px;
    border-bottom: 1px solid $link;
}
.uploadmodal {
    position: absolute;
    right: 2%;
    top:20%;
    background-color: #FFF;
    z-index: 1;
    padding: 1vh 1vw;
}

.link-question-container {
    .operator-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-right: 2.5vw;
    }

    .dropdown {
        width: 100%;

        button {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #dee2e6;
        }

        .dropdown-menu {
            width: 100%;
        }
    }

    .link-questions-all-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .link-questions-all {
            display: flex;
            flex-direction: column;
            background-color: #f0f0f0;
            padding: 1rem;
            gap: 0.5rem;
        }

    }

    .link-question-action-container {
        display: flex;
        gap: 1rem;
    }
}

.qimage {
    width: 100px;
    height: 100px;
}
.qimage-container {
    display: flex;
    flex-direction: row ;
}
@media (max-width: 800px) {
    .assessment-add-container {
        div>.d-flex {
                flex-direction: column !important;
                align-items: flex-start !important;
                label {
                    width: 100% !important;
                }
            }
        
        }
        div .d-flex > .rowcenter {
            flex-direction: row !important;
            align-items: center !important;
            align-self: center !important;
            width: 100% !important;
        }
        div>.d-flex>.icons {
            flex-direction: row !important;
            width: 100% !important;
            align-items: center !important;
        }
        
    .question-title>.d-flex  {
        flex-direction: column !important;

        // align-items: flex-start !important;
        .w-90 {
            flex-direction: column !important;
            align-items: flex-start !important;

            div {
                margin: 0.5vh 0vw;
                width: 100% !important;
            }
        }

        img {
            width: 20px !important;
        }
        .qimage {
            img {
            width: 100px !important;
            height: 100px !important;
            }
        }
    }

    .qimage-container {
        flex-direction: row !important;

    }
}