@import './input-style/input-lable.scss';
@import './shared/scss/theme.scss';

.App {
  text-align: center;
  font-size: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.display-flex {
  display: flex;
}

.h-center {
  align-items: center;
}

.v-center {
  justify-content: center;
}

.full-width-height {
  width: 100%;
  height: 100%;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.text-info {
  font-size: 12px;
  color: #575757 !important;
  font-weight: bold;
}

.datepicker-control {
  display: flex;
  flex-direction: column;

  input {
      width: 100%;
  }

  .react-datepicker-wrapper .react-datepicker__input-container {
      display: flex;
      align-items: center;
  }
}

.search-container {
  flex-direction: column;
  gap: 25px;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 4%;
  padding-right: 4%;
  background-color: #fffaee;
}

.font-weight-bold {
  font-weight: bold;
}

ins.adsbygoogle[data-ad-status="unfilled"] {
    display: none !important;
}

.form-check {

  input {
    cursor: pointer;
  }

  label {
    cursor: pointer;
  }
}

.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}