@import '../scss/partials/variables';

.table-box {
    width: 100%;
    border-spacing: 0;

    // head styles
    .table-head {
        background-color: $table-header;

        .table-heading {
            font-weight: bold;
            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
        }

        .row-height {
            height: 50px;
            background-color: inherit;
        }
    }

    // body styles
    .table-body {
        // max-height: 500px;
        width: auto;

        .row-height {
            height: 55px;
            max-height: 55px;
        }

        .row-bg-odd {
            background-color: $table-odd;
        }

        .row-bg-even {
            background-color: $table-even;
        }

        .row-bg-accordion {
            background-color: $table-accordion;
        }

        .action-button {
            height: 25px;
            width: 25px;
            cursor: pointer;
            margin: 0 6px;
            border-radius: 10%;
        }

        .accordion-alignment {
            cursor: pointer;
            float: right;
            padding-top: 4%;
            padding-bottom: 4%;
            color: #e1e1e1;

        }

        @media (max-width:1000px) {
            .accordion-alignment {
                float: right !important;
                margin-top: -25px;
                color: #e1e1e1;
                cursor: pointer
            }
        }

        @media (min-width:1000px) {
            .accordion-alignment {
                padding: 5% 1%;
                float: right !important;
                margin-top: -25px;
                color: #e1e1e1;
                cursor: pointer
            }
        }

        // .action-button:hover {
        //     box-shadow: 1px 1px 4px 3px $primary;
        // }
    }
}

.tooltip {

    .tooltip-arrow {
        display: none !important;
    }

    .tooltip-inner {
        background-color: $white !important;
        color: $dark !important;
        border: 1px solid $secondary;
        font-size: 12px !important;
    }
}

.row-border {
    border-bottom: 1px solid $grey-dark;
}

.switch-enable {
    cursor: pointer;
}

.switch-enable:hover {
    box-shadow: 1px 1px 4px 2px $primary;
}

.switch-enable:active {
    background-color: $primary;
}

.switch-disable {
    cursor: not-allowed;
    color: #2125297a;
}

.page-switch {
    padding: 5px;
    font-size: 14px;
    font-weight: 'bold';
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.unsort-90 {
    transform: rotate(90deg);
}

.datatable-loader-img {
    width: 5%;
    height: 5%;
}