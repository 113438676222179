
.home-container {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    .rowview > div{
        font-size:x-small;
    }
    img {
        width: 20%;
    }
    .header {
        font-weight: bold;
        align-self: center;
        text-align: center;
    }
    .border-btn {
        color: #5c2e91;
        border-bottom: 1px solid #5c2e91;
        margin: 0px 1vw;
    }
    .borderstyle {
        border-color: #00BBFF !important;
    }
    .dheader {
        font-weight: bold;
        font-size:smaller;
        padding: 2vh 0vw;
        color: gray;
    }
    thead {
        background-color: #f8f9fb;
        position:fixed
        div {
            width: 13vw
        }

    }
    .tableContainer{
        padding: 0 10px 10px 10px;
        height: 250px;
        overflow-y: scroll;
        position: relative;
        width: 100%;
        thead {
            position: sticky;
            top: 0;
            z-index: 1; 
        }
    }
}
        @media (max-width: 450px) {
            .home-container > div {
                    flex-direction: column !important;
                    align-items: flex-start !important;
                    div {
                        margin-top: 2px;
                        img {
                            width: 30px;
                        }
                    }
            }
    
        }
