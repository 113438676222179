
@import '../../../shared/scss/partials/variables';
.assessment-list {
    position: relative;
    .search-container {
        flex-direction: column;
        gap: 25px;
        padding-top: 2%;
        padding-bottom: 2%;
        padding-left: 4%;
        padding-right: 4%;
        background-color: #fffaee;
    }
    .searchinput {
        // border: 0px;
        background-color: #f8f9fa;
        border: 1px solid $primary;
        // border: 0px !important;
        // border-top: 1px solid $primary !important;
        // border-bottom: 1px solid $primary !important;
    }
    .searchinput:focus {
        box-shadow: none !important;
        background-color: #f8f9fa !important;
        border: 1px solid $primary !important;
        // border: 0px !important;
        // border-top: 1px solid $primary !important;
        // border-bottom: 1px solid $primary !important;

    }
    .create-button {
        bottom: 20px;
        position: sticky;
        margin: 10px;
    }
}