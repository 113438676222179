.search-container {
    flex-direction: column;
    gap: 25px;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 4%;
    padding-right: 4%;
    background-color: #fffaee;
}
.location-form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid rgb(204, 198, 198);
    padding: 30px;
    width: 700px;
}
.location-form-label {
    margin-bottom: 0.2rem !important;
    color: #5c2e91;
}
.error-message {
    color: red;
    font-size: 12px;
}