.assessmet-list-container {
    width: 100%;
    height: 100%;

    .header {
        font-weight: bold;
        align-self: center;
        text-align: center;
    }
    .head-name {
        font-weight: bold;
        font-size: large;
        color: #ee7058;
    }

    .tableContainer{
        padding: 0 10px 10px 10px;
        height: 250px;
        overflow-y: scroll;
        position: relative;
        width: 100%;
        thead {
            // position: sticky;
            // top: 0;
            // z-index: 1; 
            background-color: #fafafa;
        }
        td,th{
            border: 1px solid #e7e7e9;
            padding-left: 10px;
            div {
                padding: 0.5vh 0vw;
            }
        }
    }
        @media (max-width: 1200px) {
    
            .head-name {
                font-size: smaller !important;
            }
        }
        @media (max-width: 800px) {
    
            .head-name {
                font-size: 10px !important;
            }
        }
}

@media (max-width: 500px) {
    .assessmet-list-container  > div {
             flex-direction: column !important;
             align-items: flex-start !important;
             img {
                 width: 20px;
             }
             div {
                 margin-top: 2px;
                 
             }
     }

 }