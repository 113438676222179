
@import '../../shared/scss/partials//variables';

.sidebar-box {
    position: fixed;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid rgb(233, 232, 232);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;

    .side-menu-container {
        list-style-type: none;
        padding: 0;
        margin: 0;

        a {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px;
            font-weight: bold;
            color: #000000;

            i {
                font-size: 15px;
            }
        }

        a:hover {
            color: $primary;
            background-color: $secondary;
        }

        .active-menu>a:hover {
            color: #ffffff;
            background-color: $primary;
        }
    }

    .sub-menu {

        a {
            padding-left: 30px;
        }
    }

    .active-menu>a {
        color: #ffffff;
        background-color: $primary;
        border: 1px solid $primary;
        border-radius: 5px;
        max-height: 20vh;
        margin: 5px 0px;
    }

    .sub-menu {
        background-color: #da8111;
    }

@media (max-width: 800px) {
    .menu-title {
        display:none !important;
    }
    .menu {
        width: "50%";
        img {
            width:25px;
        }
    }
}
   
}