@import '../../../shared/scss/partials/variables';
.take-assessment-container {
    .loader-img {
    }
    width: 95%;
    height: 95%;
    // box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    .assess-title {
        font-weight: bold;
        color: $grey;
        font-size: larger;
    }
    .right-content {
        border-left: 1px solid $grey-dark;
        border-right: 1px solid $grey-dark;
        padding: 0vh 2vw;

    }
    .assess-description {
        font-weight: bold;
        color: $grey;
        font-size: small;
    }
    .borderui {
        border-bottom: 1px solid $grey-dark;
        margin-top: 5%;
    }
    .clock {
        font-size: small;
    }
    .img-text {
        font-size: smaller;
        text-align: center;
    }
    .question-ui {
        border: 1px solid $grey-dark;
        padding: 2vh 1vw;
        margin: 1vh 1vw;
    }
    .question-title {
        font-weight: bold;
        border-bottom: 1px solid $grey-dark;
        padding-bottom: 10px;
        display: flex;
    }
    .question-container {
        display: flex;
        flex-direction: column;
        padding: 2rem;
        gap: 1.5rem;

        .question {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 1.5rem;
            border: 1px solid rgba(128, 128, 128, 0.438);
            box-shadow: 1px 1px 10px #c6bebe;
            border-radius: 8px;
        }
    }

    .option-container {
        display: flex;
        gap: 3rem;

        .option {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 2rem;
            border-radius: 10px;
            border: 1px solid #88888898;
            width: 200px;

            label {
                width: 100%;
                padding: 10px;
            }
        }
    }

    .assessment-action-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        gap: 1rem;
    }
}

.take-no-assessment, .take-assessment-liability, .take-assessment-completed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}