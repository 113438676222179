.multi-dropdown-container {
    position: relative;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    .selected-option {
        border: 1px solid #ced4da;
        background-color: #FFFFFF;
        padding: 0.375rem 0.75rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        border-radius: 5px;
    }

    .dropdown-container {
        width: 100%;
        position: absolute;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 999;
        background-color: #ffffff;
        min-height: 50px;
        max-height: 350px;
        overflow: auto;
    }

    .dropdown-option {
        display: flex;
        padding: 10px;
        gap: 10px;

        input {
            pointer-events: none;
        }
    }

    .selected-option:hover {
        cursor: pointer;
    }

    .dropdown-option:hover {
        cursor: pointer;
        background-color: #ebebeb;
    }
    
    .dropdown-footer {
        display: flex;
        gap: 10px;
        padding: 10px;
        text-align: center;
        justify-content: end;
    }

    .drop-btn {
        background-color: #5c2e91;
        color: #fff;
        cursor: pointer;
    }

    .w-10 {
        width: 10%;
        padding: 5px;
    }
}